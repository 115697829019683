<template>
	<v-dialog
            v-model="dialog_val"
            :max-width="width"
            :persistent="persistent"
            internal-activator
            :overlay-opacity="type === 'confirm' ? 0.8 : type === 'file'? 0 : 0.6"
            transition="scale-transition"
            @click:outside="$emit('close')"
            @keydup.esc="$emit('close')"
            :fullscreen="$vuetify.breakpoint.width < 600"
    >
        <div class="cell fill-height" v-if="!type || type === 'file'" :class="$vuetify.breakpoint.width < 600 ? '' : 'rounded-lg'">
            <v-row class="mx-auto primary" align="center" v-if="title">
                <v-col class="py-3">
                    <div class="white--text sub-title-2">{{ title }}</div>
                </v-col>

                <slot name="actions"></slot>

                <v-col cols="auto" class="py-3 pl-0">
                    <Button iconVal="mdi-close" :icon="true" :small="true" :iconSmall="true" iconColor="white" :click="() => $emit('close')" />
                </v-col>
            </v-row>
            <v-row class="mx-0 fill-height" justify="center" :style="$vuetify.breakpoint.width < 600 ? {maxHeight: ($vuetify.breakpoint.height - 56) + 'px'}:{maxHeight: ($vuetify.breakpoint.height * 0.9 - 56) + 'px'}" style="overflow-y: auto; overflow-x: hidden">
                <v-col class="pa-0">
                    <slot name="content"></slot>
                </v-col>
            </v-row>
        </div>
        <div class="cell rounded-lg" v-if="type === 'confirm'">
            <v-row class="mx-0">
                <v-col class="pa-4">
                    <div class="sub-title-2">{{ message }}</div>
                </v-col>
            </v-row>
            <v-row class="mx-0">
                <v-col>
                    <Button
                            large
                            block
                            color="celldark"
                            dark
                            :label="$t('global.action.no')"
                            :loading="loading"
                            :click="() => $emit('cancel')"
                    />
                </v-col>
                <v-col>
                    <Button
                            large
                            block
                            color="primary"
                            :label="$t('global.action.yes')"
                            :loading="loading"
                            :click="() => $emit('confirm')"
                    />
                </v-col>
            </v-row>
        </div>
    </v-dialog>
</template>

<script>
    export default {
		name: "Dialog",
        props: {
            value:{},
            title:{},
            type:{},
            message:{},
            loading:{},
            width: {default: '400'},
            persistent: {type: Boolean},
            styles:{}
            },
        components: {
		    Button: () => import('@/components/ui/Button.vue')
        },
        computed: {
		    dialog_val: {
		        get(){
		            return this.value
                },
                set(val){
		            this.$emit('input', val)
                }
            }
        }
	}
</script>

<style scoped>

</style>
